import React from 'react';
import EnquiryForm from '../Home/EnquiryForm';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import bg from '../../assets/images/contact.jpg';
import ContactCards from './ContactCards';
import MapComponent from '../Showrrom/MapComponent';

const Contact = () => {
    return (
        <>
            {/* Hero Section */}
            <div className="relative flex flex-col items-center justify-center min-h-screen bg-gray-100">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `url(${bg})`,
                    }}
                >
                    <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-80"></div>
                </div>

                {/* Hero Content */}
                <div className="relative z-10 text-center text-white px-6">
                    <h1 className="text-5xl font-extrabold tracking-wide mb-4">
                        Contact MP Group
                    </h1>
                    <p className="text-xl max-w-3xl mx-auto leading-relaxed">
                        Connect with us to explore world-class tile and marble solutions. We’re just a call or message away!
                    </p>
                </div>
            </div>

            <section className="bg-white py-16 px-6 md:px-12">
                <div className="max-w-7xl mx-auto">
                    <div className="text-center mb-12">
                        <h2 className="text-4xl font-bold text-gray-800">Get in Touch</h2>
                        <p className="text-lg text-gray-600 mt-2">
                            Reach out to us using the details below or visit us at our office.
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Phone Card */}
                        <div className="flex flex-col items-center bg-gray-50 p-8 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
                            <FaPhoneAlt className="text-blue-600 text-4xl mb-4" />
                            <h3 className="text-lg font-semibold text-gray-800">Call Us</h3>
                            <p className="text-gray-600 mt-2">+977-1-4990052 | 4112245 | 6637119 | 5919918</p>
                        </div>

                        {/* Email Card */}
                        <div className="flex flex-col items-center bg-gray-50 p-8 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
                            <FaEnvelope className="text-yellow-600 text-4xl mb-4" />
                            <h3 className="text-lg font-semibold text-gray-800">Email Us</h3>
                            <p className="text-gray-600 mt-2">Marble2068@gmail.com</p>
                        </div>

                        {/* Address Card */}
                        <div className="flex flex-col items-center bg-gray-50 p-8 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
                            <FaMapMarkerAlt className="text-green-600 text-4xl mb-4" />
                            <h3 className="text-lg font-semibold text-gray-800">Visit Us</h3>
                            <p className="text-gray-600 mt-2">Kathmandu | Bhaktapur | Banepa</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Cards */}
            <div id="place">
                <ContactCards />
            </div>

            {/* Enquiry Form Section */}
            <section id="enquiry-form" className="bg-white py-16 px-6 md:px-12">
                <div className="max-w-7xl mx-auto text-center">
                    <h2 className="text-4xl font-bold text-gray-800 mb-8">
                        Send Us an Enquiry
                    </h2>
                    <p className="text-lg text-gray-600 mb-12">
                        Fill out the form below, and we’ll respond promptly to your queries or concerns.
                    </p>
                    <div className="max-w-3xl mx-auto">
                        <EnquiryForm />
                    </div>
                </div>
            </section>
            <MapComponent />
        </>
    );
};

export default Contact;
