import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Divider } from '@mui/material';
import { FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

const ContactCards = () => {
    const companies = [
        {
            name: 'Marble Palace Industries Pvt. Ltd.',
            addresses: [
                { label: 'H/O', location: 'Jadibuti-Pepsicola Road, Chapro, Kathmandu', phone: '01-4990052 / 01-4990149' },
                { label: 'B/O', location: 'Airport Road, Tinkune, Kathmandu', phone: '01-4112245 / 01-4112201' },
            ],
        },
        {
            name: 'Indreni Marble Plaza Pvt. Ltd.',
            addresses: [
                { label: 'H/O', location: 'Near Pepsicola Ward 32, Kathmandu', phone: '01-5919918' },
                { label: 'B/O', location: 'Shankharapur, Saakhu, Kathmandu', phone: '01-4451783' },
            ],
        },
        {
            name: 'Marble Plaza Pvt. Ltd.',
            addresses: [
                { location: 'Old Bhaktapur Road, Thimi, Bhaktapur', phone: '01-6637119' },
            ],
        },
        {
            name: 'Banepa Marble Palace Pvt. Ltd.',
            addresses: [
                { location: 'Main Banepa Road, Banepa' },
            ],
        },
        {
            name: 'KAJARIA ETERNITY WORLD',
            addresses: [
                { location: 'NEAR PEPSICOLA WARD 32, KATHMANDU', phone: '01-5919918' },
            ],
        },
        {
            name: 'NEXION STUDIO',
            addresses: [
                { location: 'NEAR PEPSICOLA WARD 32, KATHMANDU', phone: '01-5919918' },
            ],
        },

    ];

    return (
        <Box sx={{ backgroundColor: '#f8f8f8', py: 5, px: 3 }}>
            <Grid container spacing={4} justifyContent="center">
                {companies.map((company, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                        <Card
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                background: 'linear-gradient(145deg, #ffffff, #e6e6e6)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
                                },
                            }}

                        >
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    fontWeight="bold"
                                    gutterBottom
                                    sx={{
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        fontSize: '1rem',
                                    }}
                                >
                                    {company.name}
                                </Typography>
                                <Divider sx={{ my: 2 }} />
                                {company.addresses.map((addr, idx) => (
                                    <Box key={idx} mb={2}>
                                        {addr.label && (
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{
                                                    fontWeight: 'medium',
                                                    fontSize: '0.85rem',
                                                    mb: 0.5,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {addr.label}
                                            </Typography>
                                        )}
                                        <Box display="flex" alignItems="center" mb={0.5}>
                                            <FaMapMarkerAlt style={{ marginRight: 6, color: '#34C759' }} />
                                            <Typography variant="body2" color="textSecondary">
                                                {addr.location}
                                            </Typography>
                                        </Box>
                                        {addr.phone && (
                                            <Box display="flex" alignItems="center">
                                                <FaPhone style={{ marginRight: 6, color: '#3498DB' }} />
                                                <Typography variant="body2" color="textSecondary">
                                                    {addr.phone}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>

    );
};

export default ContactCards;
