import React, { useEffect, useState, useMemo } from "react";
import { fetchproductsubcategory } from "../../Redux/Slice/GetCategorySlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import imgg from "../../assets/images/myImage.jpg";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import classNames from "classnames";

const FilterSection = ({ title, options, selectedOption, onSelect, toggleFilter, filterType, isActive }) => {
    return (
        <div className="bg-white shadow-lg rounded-lg p-6 mb-6 transition-all duration-300 ">
            <div className="flex justify-between items-center">
                <h3 className="text-xl font-bold text-gray-900">{title}</h3>
                <button
                    onClick={() => toggleFilter(filterType)}
                    className="text-gray-500 text-2xl transition-transform duration-300 transform hover:scale-110"
                >
                    {isActive ? <AiOutlineMinus /> : <AiOutlinePlus />}
                </button>
            </div>

            {isActive && (
                <div className="space-y-3">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            onClick={() => onSelect(option)}
                            className={`cursor-pointer py-3 px-4 rounded-lg transition-all duration-300 ease-in-out ${selectedOption === option
                                ? "bg-gradient-to-r from-blue-500 to-indigo-500 text-white scale-105"
                                : "bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                                }`}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const ProductSubcategory = () => {
    const { productsData } = useSelector((state) => state.category);
    const { type, id, name } = useParams();
    const dispatch = useDispatch();
    const [selectedSize, setSelectedSize] = useState("All");
    const [selectedFinish, setSelectedFinish] = useState("All");
    const [selectedBrand, setSelectedBrand] = useState("All");
    const [activeFilter, setActiveFilter] = useState(null);

    const sizes = useMemo(() => ["All", ...new Set(productsData.map((product) => product.size))], [productsData]);
    const finishes = useMemo(() => ["All", ...new Set(productsData.map((product) => product.finish))], [productsData]);
    const brandname = useMemo(() => ["All", ...new Set(productsData.map((product) => product.brandname))], [productsData]);

    useEffect(() => {
        dispatch(fetchproductsubcategory({ type, id }));
    }, [dispatch, id, type]);

    const filteredProducts = useMemo(() =>
        productsData.filter(
            (product) =>
                (selectedSize === "All" || product.size === selectedSize) &&
                (selectedFinish === "All" || product.finish === selectedFinish) &&
                (selectedBrand === "All" || product.brandname === selectedBrand)
        ), [productsData, selectedSize, selectedFinish, selectedBrand]);

    const toggleFilter = (filter) => {
        setActiveFilter((prev) => (prev === filter ? null : filter));
    };

    return (
        <div className="min-h-screen bg-white pt-20">
            {/* Header */}

            <div className="container bg-gray-800 mx-auto text-center py-10 ">
                <p className="text-white text-sm font-sans uppercase tracking-wider mb-4">
                    <Link to='/'>
                        <span className="text-blue-500 font-semibold">
                            Home
                        </span>
                    </Link>
                    {` / `}
                    <span className="text-white font-semibold">
                        {name}
                    </span>
                    {` / `}
                    <span className="text-white font-semibold text-lg">
                        {selectedSize}
                    </span>
                    {` / `}
                    <span className="text-white font-semibold text-lg">
                        {selectedFinish}
                    </span>
                </p>

                <h2 className="text-3xl md:text-4xl font-bold text-white mt-2 mb-6">
                    {name}  {selectedSize !== 'All' ? '~' + selectedSize : ''} {selectedFinish !== 'All' ? '~' + selectedFinish : ''}
                </h2>

                <div className="w-full h-[1px] bg-gray-300 mb-6 mx-auto max-w-[150px] rounded-full"></div>
            </div>


            {/* Main Content */}
            <div className="container mx-auto py-12 flex flex-col lg:flex-row gap-6 p-3">
                <div className="lg:w-1/4 lg:pr-6">
                    <FilterSection
                        title="By Size"
                        options={sizes}
                        selectedOption={selectedSize}
                        onSelect={setSelectedSize}
                        toggleFilter={toggleFilter}
                        filterType="size"
                        isActive={activeFilter === "size"}
                    />
                    <FilterSection
                        title="By Finish"
                        options={finishes}
                        selectedOption={selectedFinish}
                        onSelect={setSelectedFinish}
                        toggleFilter={toggleFilter}
                        filterType="finish"
                        isActive={activeFilter === "finish"}
                    />

                    <FilterSection
                        title="By Brand"
                        options={brandname}
                        selectedOption={selectedBrand}
                        onSelect={setSelectedBrand}
                        toggleFilter={toggleFilter}
                        filterType="brandname"
                        isActive={activeFilter == "brandname"}
                    />
                </div>

                {/* Products */}
                <div className="flex-1">
                    {filteredProducts.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 ">
                            {filteredProducts.map((product, index) => (
                                <Link
                                    to={`/product-details/${product.id}/${product.category_id}`}
                                    key={index}
                                    className="group bg-white rounded-lg shadow-md hover:shadow-lg overflow-hidden transition-shadow"
                                >
                                    <div className="relative border border-gray-300">
                                        <img
                                            src={product.ImageUrl || imgg}
                                            alt={product.sku}
                                            className="w-full h-56 object-cover group-hover:scale-105 transition-transform duration-300"
                                        />
                                        {product.size && (

                                            <span className="absolute top-2 left-2 bg-gray-800 text-white text-xs px-2 py-1 rounded-lg shadow-md">
                                                {product.size}
                                            </span>
                                        )}
                                    </div>
                                    <div className="p-4 text-center border border-gray-300">
                                        <h3 className="text-lg font-medium text-gray-900">
                                            {product.brandname === "KAJARIA" ? product.brandname : product.name}
                                        </h3>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center mt-20">
                            <h3 className="text-2xl font-semibold text-gray-700">
                                No products found!
                            </h3>
                            <p className="text-gray-500 mt-2">
                                Try selecting a different filter to see available products.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductSubcategory;
